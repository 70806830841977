import React, { useEffect, useState } from "react";

//Package
import { styled } from "styled-components";
import { mccConfig } from "../../axiosConfig";
import ReactPaginate from "react-paginate";

//Image
import bgimage from "../assets/images/service-top-bg.png";
function Projects() {
    // Scroll to top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // // Api Connection
    const [value, setValue] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        next: null,
        previous: null,
        total_pages: 1, // Set the initial value
        total_results: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // This provides a smooth scrolling animation
        });
    }

    useEffect(() => {
        mccConfig
            .get("api/v1/projects/")
            .then((response) => {
                setValue(response.data.results);
                setPagination(response.data);
            })
            .catch((err) => {});
    }, []);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        const nextPageUrl = selectedPage > currentPage - 1 ? pagination.next : pagination.previous;

        if (nextPageUrl) {
            mccConfig
                .get(nextPageUrl)
                .then((response) => {
                    setValue(response.data.results.careers);
                    setPagination(response.data);
                })
                .catch((err) => {});
        }
        scrollToTop();
    };
    return (
        <>
            <MainContainer>
                <TopContainer>
                    <MainHeading>Projects</MainHeading>
                    <SubHeading>HOME / PROJECTS</SubHeading>
                </TopContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <BottomContainer>
                            {value.map((item) => (
                                <ServiceCard>
                                    <TopBox>
                                        <MainImageBox>
                                            <MainImg src={item.image} />
                                        </MainImageBox>
                                        <NameTitle>{item.name}</NameTitle>
                                        <Hr />
                                    </TopBox>
                                    <BottomBox>
                                        <MainList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../assets/images/project-icon1.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Client</Title>
                                                    <SubTitle>{item.client}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../assets/images/project-icon2.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Duration</Title>
                                                    <SubTitle>{item.duration}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../assets/images/project-icon3.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Size</Title>
                                                    <SubTitle>{item.size}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                            <SubList>
                                                <IconBox>
                                                    <IconImg
                                                        src={
                                                            require("../assets/images/project-icon4.svg")
                                                                .default
                                                        }
                                                    />
                                                </IconBox>
                                                <InfoBox>
                                                    <Title>Cost</Title>
                                                    <SubTitle>{item.cost}</SubTitle>
                                                </InfoBox>
                                            </SubList>
                                        </MainList>
                                    </BottomBox>
                                </ServiceCard>
                            ))}
                        </BottomContainer>
                    </SubContainer>
                    <PaginationContainer>
                        <PaginationText></PaginationText>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pagination.total_pages}
                            onPageChange={handlePageClick}
                            forcePage={currentPage - 1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </PaginationContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Projects;

const MainContainer = styled.div``;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const TopContainer = styled.div`
    padding: 165px 150px 92px 150px;
    background-color: #e9f9ff;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: 19%;
    background-position: right;
    @media all and (max-width: 640px) {
        text-align: center;
    }
    @media all and (max-width: 480px) {
        padding: 140px 90px 79px;
    }
`;
const BottomContainer = styled.div`
    padding: 70px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const MainHeading = styled.h2`
    color: #215366;
    font-family: "poppins_medium";
    font-size: 40px;
    margin-bottom: 20px;
`;
const SubHeading = styled.h4``;

const ServiceCard = styled.div`
    padding: 30px;
    width: 48%;
    margin-bottom: 45px;
    border: 1px solid var(--ui-design-main-primary, #00aae8);
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 20px auto;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;
const TopBox = styled.div``;
const MainImageBox = styled.div`
    margin-bottom: 20px;
    height: 354px;
    @media all and (max-width: 480px) {
        height: 170px;
    }
`;
const MainImg = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: fill;
`;
const NameTitle = styled.h3`
    color: #222222;
    font-size: 25px;
    margin-bottom: 20px;
    @media all and (max-width: 1200px) {
        font-size: 19px;
    }
`;
const Hr = styled.hr`
    border: 1px solid #a0a0a0;
    margin-bottom: 30px;
`;
const BottomBox = styled.div``;
const MainList = styled.ul`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const SubList = styled.li`
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    width: 45%;
    &:nth-last-child(-n + 2) {
        margin-bottom: 0;
    }
    @media all and (max-width: 1200px) {
        width: 46%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        &:nth-last-child(-n + 2) {
            margin-bottom: 25px;
        }
    }
`;
const IconBox = styled.div`
    width: 30px;
    margin-right: 15px;
    @media all and (max-width: 480px) {
        width: 20px;
    }
`;
const IconImg = styled.img`
    display: block;
    width: 25px;
`;

const InfoBox = styled.div`
    /* height: 94px;
    @media all and (max-width: 480px) {
        height: 0;
    } */
`;
const Title = styled.h6`
    color: #222222;
    font-family: "poppins_regular";
`;
const SubTitle = styled.div`
    color: #222222;
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    margin-left: 20px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
