import React from "react";
import Spotlight from "./Spotlight";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Projects from "./Projects";
import Ventures from "./Ventures";
import JoinUs from "./JoinUs";
import Success from "./Success";
import ContactUs from "./ContactUs";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import Header from "../../includes/Header";

function HomeLandingPage({ latestcareers }) {
    return (
        <div>
            <Spotlight />
            <AboutUs Link />
            <Services />
            <Projects />
            <Ventures />
            <JoinUs latestcareers={latestcareers} />
            <Success />
            <ContactUs />
        </div>
    );
}

export default HomeLandingPage;
