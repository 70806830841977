import React from "react";
import { styled } from "styled-components";

//Image
import topbgimage from "../../assets/images/service-top-bg.png";
import bgimage from "../../assets/images/spotlight-bg.png";

function Success() {
    return (
        <>
            <MainContainer>
                <BackgroundContainer>
                    <WrapperContainer className="wrapper">
                        <SubContainer>
                            <TopContainer>
                                <Small>- Success</Small>
                                <Heading>
                                    <span>Our </span> Happiness Clients
                                </Heading>
                            </TopContainer>
                            <BottomContainer>
                                <MainList>
                                    <SubList>
                                        <LogoBox>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/tata-logo.svg")
                                                        .default
                                                }
                                            />
                                        </LogoBox>
                                    </SubList>
                                    <SubList>
                                        <LogoBox>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/vestian-logo.svg")
                                                        .default
                                                }
                                            />
                                        </LogoBox>
                                    </SubList>
                                    <SubList className="no-margin">
                                        <LogoBox>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/kscc-logo.svg")
                                                        .default
                                                }
                                            />
                                        </LogoBox>
                                    </SubList>
                                    <SubList>
                                        <LogoBox>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/kerala-logo.svg")
                                                        .default
                                                }
                                            />
                                        </LogoBox>
                                    </SubList>
                                    <SubList className="no-margin">
                                        <LogoBox>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/inkel-logo.svg")
                                                        .default
                                                }
                                            />
                                        </LogoBox>
                                    </SubList>
                                </MainList>
                            </BottomContainer>
                        </SubContainer>
                    </WrapperContainer>
                </BackgroundContainer>
            </MainContainer>
        </>
    );
}

export default Success;

const MainContainer = styled.div`
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left;
    background-position-y: top;
`;
const BackgroundContainer = styled.div`
    background-image: url(${topbgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    background-position-y: bottom;
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    padding: 100px 0px;
`;
const TopContainer = styled.div`
    margin-bottom: 5%;
`;
const Small = styled.h4`
    margin-bottom: 20px;
`;
const Heading = styled.h2`
    font-family: "poppins_medium";
    font-size: 37px;
    width: 37%;
    margin-bottom: 20px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        font-size: 33px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const BottomContainer = styled.div``;

const MainList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const SubList = styled.li`
    padding: 30px;
    border: 1px solid var(--ui-design-neutral-300, #e3e3e3);
    background: #fff;
    width: 30%;
    margin: 0px 35px 50px 0px;
    &.no-margin {
        margin-right: 0;
        @media all and (max-width: 980px) {
            margin: 0px 35px 50px 0px;
        }
        @media all and (max-width: 768px) {
            margin: 0px 0px 20px 0px;
        }
    }
    @media all and (max-width: 980px) {
        width: 43%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
        margin: 0px 0px 20px 0px;
    }
`;
const LogoBox = styled.div`
    width: 48%;
    margin: 0 auto;
    aspect-ratio: 3/2;
    @media all and (max-width: 980px) {
        width: 50%;
    }
`;
const LogoImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
