import { Route, Routes } from "react-router-dom";

import React, { useEffect, useState } from "react";
import HomeLandingPage from "../../screens/home/HomeLandingPage";
import Projects from "../../screens/Projects";
import Careers from "../../screens/Careers";
import Header from "../../includes/Header";
import Footer from "../../includes/Footer";
import { mccConfig } from "../../../axiosConfig";

function AppRouter() {
    const [data, setData] = useState([]);
    const [latestcareers, setLatestcareers] = useState([]);

    const [pagination, setPagination] = useState({
        current_page: 1,
        next: null,
        previous: null,
        total_pages: 1, // Set the initial value
        total_results: 0,
    });
    useEffect(() => {
        mccConfig
            .get("api/v1/careers/")
            .then((response) => {
                setData(response.data.results.careers);
                setLatestcareers(response.data.results.latest_careers);
                setPagination(response.data);
            })
            .catch((err) => {});
    }, []);

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" exact element={<HomeLandingPage latestcareers={latestcareers} />} />
                <Route path="/projects" exact element={<Projects />} />
                <Route
                    path="/careers"
                    exact
                    element={
                        <Careers
                            setData={setData}
                            setPagination={setPagination}
                            pagination={pagination}
                            data={data}
                        />
                    }
                />
            </Routes>
            <Footer />
        </div>
    );
}

export default AppRouter;
