import React from "react";

//Packages
import { styled } from "styled-components";

function AboutUs() {
    return (
        <>
            <MainContainer id="about" name="about">
                <WrapperContainer className="wrapper">
                    <TopContainer>
                        <SubContainer>
                            <LeftContainer>
                                <AboutImgBox>
                                    <AboutImg
                                        src={require("../../assets/images/about-spotimg.png")}
                                    />
                                </AboutImgBox>
                            </LeftContainer>
                            <RightContainer>
                                <Small>- ABOUT US</Small>
                                <Heading>
                                    Why <span>Choose us</span> for best construction experience
                                </Heading>
                                <Para>
                                    We are a recognized name in Kerala state Civil Constructions
                                    sector and holding ‘A’ class license of PWD department of Kerala
                                    state Government. our major motive is transforming ideas into
                                    bigger realities. Malabar construction & contractors proves
                                    innovative and sustainable solutions reinforced by robust
                                    technology and fully integrated systems.
                                </Para>
                                <MainList>
                                    <SubList>
                                        <ArrowBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowBox>
                                        <SubHeading>Provides Sustainable Solutions</SubHeading>
                                    </SubList>
                                    <SubList>
                                        <ArrowBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowBox>
                                        <SubHeading>Well Equipped Technology</SubHeading>
                                    </SubList>
                                    <SubList>
                                        <ArrowBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowBox>
                                        <SubHeading>Reinforced by Robust Technology</SubHeading>
                                    </SubList>
                                </MainList>
                            </RightContainer>
                        </SubContainer>
                    </TopContainer>
                    <BottomContainer>
                        <AboutCard>
                            <ImageIcon>
                                <Icon src={require("../../assets/images/flag-icon.svg").default} />
                            </ImageIcon>
                            <Title>- Mission</Title>
                            <SubTitle>
                                <span>Generate</span> Consistent & Sustainable Returns for our
                                Investors
                            </SubTitle>
                            <SubPara>
                                To grow into an all-rounder company with a good business reputation
                                with commitment in building pride for our company by creating trust
                                and satisfaction for our client.
                            </SubPara>
                        </AboutCard>
                        <AboutCard>
                            <ImageIcon>
                                <Icon
                                    src={require("../../assets/images/compass-icon.svg").default}
                                />
                            </ImageIcon>
                            <Title>- Vision</Title>
                            <SubTitle>
                                <span>Provide</span> Innovative Construction Solution to our Clients
                            </SubTitle>
                            <SubPara>
                                Committed to excellence and innovation, we build trust and
                                satisfaction, forging a strong all-rounder company with a stellar
                                reputation.
                            </SubPara>
                        </AboutCard>
                    </BottomContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default AboutUs;

const MainContainer = styled.div`
    padding: 100px 0px;
    @media all and (max-width: 480px) {
        padding: 70px 0px;
    }
`;
const WrapperContainer = styled.div``;
const TopContainer = styled.div`
    margin-bottom: 50px;
`;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1200px) {
        align-items: center;
    }
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;
const LeftContainer = styled.div`
    width: 40%;
    @media all and (max-width: 1200px) {
        width: 46%;
    }
    @media all and (max-width: 1080px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const AboutImgBox = styled.div``;
const AboutImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const RightContainer = styled.div`
    width: 48%;
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 30px;
    }
`;
const Small = styled.h4`
    margin-bottom: 25px;
    font-family: "poppins_medium";
`;
const Heading = styled.h2`
    margin-bottom: 20px;
    font-family: "poppins_semibold";
    font-size: 37px;
    color: #215366;
    span {
        color: #00acea;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
`;
const Para = styled.p`
    margin-bottom: 25px;
    font-size: 17px;
    width: 85%;
    @media all and (max-width: 1200px) {
        width: 100%;
        font-size: 15px;
    }
`;
const MainList = styled.ul``;
const SubList = styled.li`
    display: flex;
    margin-bottom: 12px;
`;
const ArrowBox = styled.div`
    margin-right: 5px;
`;
const ArrowImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const SubHeading = styled.p`
    color: #000000;
    font-size: 17px;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    gap: 24px;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const AboutCard = styled.div`
    width: 49%;
    padding: 30px;
    border: 1px solid #a0a0a0;
    transition: border-color 0.3s;
    &:hover {
        border: 1px solid #00aae8;
    }
    @media all and (max-width: 1200px) {
        padding: 20px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;

const ImageIcon = styled.div`
    width: 42px;
    margin-bottom: 25px;
    @media all and (max-width: 1200px) {
        margin-bottom: 20px;
    }
`;
const Icon = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const Title = styled.h5`
    margin-bottom: 10px;
    color: #222222;
`;
const SubTitle = styled.h3`
    width: 90%;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: "poppins_medium";
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;
const SubPara = styled.p`
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
