import React from "react";

//Package
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";

//Image
import bgimage from "../../assets/images/bottom-bg.png";

function JoinUs({ latestcareers }) {
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <Small>-What We Do.</Small>
                        <TopContainer>
                            <Heading>
                                Join <span>With Us</span>
                            </Heading>
                            <Button to="/careers">View All</Button>
                        </TopContainer>
                        <BottomContainer>
                            <CardContainer>
                                {latestcareers.map((item) => (
                                    <CareerCards>
                                        <CompanyLogo>
                                            <LogoImg
                                                src={
                                                    require("../../assets/images/malabar-logo.svg")
                                                        .default
                                                }
                                            />
                                        </CompanyLogo>
                                        <Title>{item.title}</Title>
                                        <SubTitle>{item.experience}</SubTitle>
                                        <Para>{item.description}</Para>
                                        <Hr />
                                        <ApplyButton>
                                            <a href="mailto:admin@malabarconstructions.com">
                                                Apply Now
                                            </a>
                                        </ApplyButton>
                                    </CareerCards>
                                ))}
                            </CardContainer>
                        </BottomContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default JoinUs;

const MainContainer = styled.div`
    padding: 100px 0px;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left;
    background-position-y: top;
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    align-items: flex-start;
`;
const Small = styled.h4`
    margin-bottom: 20px;
`;
const Heading = styled.h2`
    font-family: "poppins_medium";
    font-size: 37px;
    margin-bottom: 20px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        font-size: 33px;
    }
    @media all and (max-width: 480px) {
        font-size: 31px;
    }
`;

const Button = styled(NavLink)`
    background-color: #00acea;
    color: #ffff;
    text-align: center;
    width: 120px;
    padding: 10px 24px;
    @media all and (max-width: 480px) {
        font-size: 28px;
        width: 97px;
        padding: 8px 19px;
        font-size: 14px;
    }
`;

const BottomContainer = styled.div``;
const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const CareerCards = styled.div`
    border: 1px solid #a0a0a0;
    padding: 30px;
    background-color: #fff;
    width: 32%;
    transition: all 0.5s ease 0s;

    &:hover {
        border: 1px solid #00aae8;
    }
    @media all and (max-width: 1200px) {
        padding: 20px;
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        width: 48%;
        margin-bottom: 26px;
    }
    @media all and (max-width: 768px) {
        width: 70%;
        margin: 15px auto;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const CompanyLogo = styled.div`
    width: 60px;
    margin-bottom: 25px;
    &.stikon {
        margin-bottom: 38px;
    }
`;
const LogoImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const Title = styled.h3`
    font-size: 21px;
    color: #222;
    font-family: "poppins_medium";
    margin-bottom: 5px;
    @media all and (max-width: 360px) {
        font-size: 19px;
    }
`;
const SubTitle = styled.h5`
    margin-bottom: 20px;
`;
const Para = styled.p`
    color: #a0a0a0;
    margin-bottom: 25px;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
`;
const Hr = styled.hr`
    border: 1px solid #a0a0a0;
    margin-bottom: 30px;
`;
const ApplyButton = styled.button`
    a {
        color: #ffffff;
    }
    color: #ffffff;
    background-color: #00acea;
    text-align: center;
    height: 42px;
    width: 100%;
`;
