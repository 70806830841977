import React, { useState } from "react";

//Packages
import { Link } from "react-scroll";
import ReactPlayer from "react-player";
import { styled } from "styled-components";

//Images
import bgimage from "../../assets/images/spotlight-bg.png";

function Spotlight({ Link }) {
    //Video Player Functions
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const toggleVideoModal = () => {
        setIsVideoOpen(!isVideoOpen);
    };

    const videoUrl =
        "https://player.vimeo.com/external/861618313.m3u8?s=52ee7cbaf302abefba8e629932415c3a06137321";

    return (
        <>
            <MainContainer id="home">
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <LeftContainer>
                            <Heading>
                                We <span>Build Dreams</span> and Construct Reality.
                            </Heading>
                            <Para>
                                Welcome to Malabar Construction & Contracts. Your Trusted Partner
                                for Exceptional Building Solutions With New Experience. Let's Create
                                Together!
                            </Para>
                            <TalkButton
                                activeClass="active"
                                to="contact"
                                spy={true}
                                duration={500}
                                smooth={true}
                                offset={-60}
                            >
                                Let's Talk
                            </TalkButton>
                        </LeftContainer>
                        <RightContainer>
                            <SpotlightImgBox>
                                <SpotlightImg src={require("../../assets/images/spoooo.png")} />
                                <PlayBox onClick={toggleVideoModal}>
                                    <PlayImg
                                        src={require("../../assets/images/play.svg").default}
                                    />
                                </PlayBox>
                            </SpotlightImgBox>
                        </RightContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
            {isVideoOpen && (
                <VideoModal>
                    <VideoCloseButton onClick={toggleVideoModal}>Close</VideoCloseButton>
                    <VideoPlayerWrapper>
                        <ReactPlayer
                            url={videoUrl}
                            width="100%"
                            height="50vh"
                            controls={true}
                            playing={true}
                        />
                    </VideoPlayerWrapper>
                </VideoModal>
            )}
        </>
    );
}

export default Spotlight;

const MainContainer = styled.div`
    background: #e9f9ff;
    padding: 250px 0px 90px 0px;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px 20px;
    @media all and (max-width: 768px) {
        padding: 120px 0px;
    }
    @media all and (max-width: 480px) {
        padding: 100px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 45%;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 25px auto;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Heading = styled.h2`
    margin-bottom: 25px;
    font-size: 48px;
    font-family: "poppins_semibold";
    line-height: 60px;
    border-radius: 50%;
    width: 84%;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        font-size: 40px;
        width: 90%;
    }
    @media all and (max-width: 1080px) {
        font-size: 37px;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        font-size: 33px;
    }
    @media all and (max-width: 360px) {
        font-size: 29px;
    }
`;
const Para = styled.p`
    margin-bottom: 25px;
    color: #222222;
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
const TalkButton = styled(Link)`
    background-color: #00acea;
    color: #ffff;
    display: block;
    text-align: center;
    width: 140px;
    padding: 10px 24px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        width: 180px;
        padding: 8px;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto;
    }
    @media all and (max-width: 768px) {
        width: 170px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    width: 47%;
    position: relative;
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0 auto;
    }
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const SpotlightImgBox = styled.div`
    background-image: url(${bgimage});
    background-position: 10px 20px;
    position: relative;
    text-align: center;
`;

const PlayBox = styled.div`
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;
const PlayImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;

const SpotlightImg = styled.img`
    width: 100%;
    display: block;
    height: 100%;
`;

const VideoModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

const VideoCloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
`;

const VideoPlayerWrapper = styled.div`
    width: 50%;
    height: auto;
    max-height: 70vh;
    @media all and (max-width: 480px) {
        width: 80%;
    }
`;
