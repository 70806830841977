import axios from "axios";

// DEVELOPER SERVER CONFIG //
// export const mccConfig = axios.create({
//     baseURL: "https://api-mcc.talrop.works/",
// });

// LIVE SERVER CONFIG //
export const mccConfig = axios.create({
    baseURL: "https://api.malabarconstructions.com",
});
