import React from "react";
import AppRouter from "./AppRouter";

function MainRouter() {
    return (
        <div>
            <AppRouter />
        </div>
    );
}

export default MainRouter;
