import React from "react";

//Packages
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

//Images
import bgimage from "../../assets/images/service-top-bg.png";

function Ventures() {
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <TopContainer>
                            <Small>- SISTER COMPANIES</Small>
                            <Heading>
                                <span>Explore</span> Our Sister Ventures
                            </Heading>
                        </TopContainer>
                        <BottomContainer>
                            <CardContainer>
                                <VentureCards>
                                    <CompanyLogo>
                                        <LogoImg
                                            src={
                                                require("../../assets/images/ifuex-logo.svg")
                                                    .default
                                            }
                                        />
                                    </CompanyLogo>
                                    <Title>iFuex</Title>
                                    <Location>Trivandrum, Kerala</Location>
                                    <Mail>business@ifuex.in</Mail>
                                    <Hr />
                                    <MoreOption to="https://ifuex.in/">
                                        <SubHeading>Visit Site</SubHeading>
                                        <ArrowImgBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowImgBox>
                                    </MoreOption>
                                </VentureCards>
                                <VentureCards>
                                    <CompanyLogo className="stikon">
                                        <LogoImg
                                            src={
                                                require("../../assets/images/stickon-logo.svg")
                                                    .default
                                            }
                                        />
                                    </CompanyLogo>
                                    <Title>Stikcon Tech Pte Ltd</Title>
                                    <Location>Singapore</Location>
                                    <Mail>business@stickon.com</Mail>
                                    <Hr />
                                    <MoreOption to="https://www.stikcon.com/">
                                        <SubHeading>Visit Site</SubHeading>
                                        <ArrowImgBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowImgBox>
                                    </MoreOption>
                                </VentureCards>
                                <VentureCards>
                                    <CompanyLogo>
                                        <LogoImg
                                            src={
                                                require("../../assets/images/stikon-logo-ai.svg")
                                                    .default
                                            }
                                        />
                                    </CompanyLogo>
                                    <Title>Project Stikcon Pvt Ltd</Title>
                                    <Location>Ernakulam, Kerala</Location>
                                    <Mail>business@stickon.com</Mail>
                                    <Hr />
                                    <MoreOption to="https://www.stikcon.com/">
                                        <SubHeading>Visit Site</SubHeading>
                                        <ArrowImgBox>
                                            <ArrowImg
                                                src={
                                                    require("../../assets/images/arrow-icon.svg")
                                                        .default
                                                }
                                            />
                                        </ArrowImgBox>
                                    </MoreOption>
                                </VentureCards>
                            </CardContainer>
                        </BottomContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Ventures;

const MainContainer = styled.div`
    padding: 100px 0px;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left;
    background-position-y: bottom;
    @media all and (max-width: 480px) {
        padding: 70px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div``;
const TopContainer = styled.div`
    margin-bottom: 30px;
`;
const Small = styled.h4`
    margin-bottom: 20px;
`;
const Heading = styled.h2`
    font-family: "poppins_medium";
    font-size: 37px;
    width: 37%;
    margin-bottom: 20px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        font-size: 33px;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const BottomContainer = styled.div``;
const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const VentureCards = styled.div`
    border: 1px solid #a0a0a0;
    padding: 30px;
    width: 32%;
    background-color: #fff;
    &:hover {
        border: 1px solid #00aae8;
    }
    @media all and (max-width: 1200px) {
        padding: 20px;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 15px auto;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const CompanyLogo = styled.div`
    width: 90px;
    margin-bottom: 25px;
    &.stikon {
        margin-bottom: 38px;
    }
`;
const LogoImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const Title = styled.h3`
    font-size: 20px;
    color: #222;
    font-family: "poppins_medium";
    @media all and (max-width: 1080px) {
        font-size: 18px;
    }
`;
const Location = styled.h5`
    color: #a0a0a0;
    margin-bottom: 20px;
`;
const Mail = styled.p`
    margin-bottom: 20px;
`;
const Hr = styled.hr`
    border: 1px solid #a0a0a0;
    margin-bottom: 30px;
`;
const MoreOption = styled(NavLink)`
    display: flex;
    width: 36%;
    cursor: pointer;
    justify-content: space-between;
    @media all and (max-width: 1200px) {
        width: 46%;
    }
    @media all and (max-width: 768px) {
        width: 48%;
    }
`;
const SubHeading = styled.span`
    color: #00acea;
`;
const ArrowImgBox = styled.div`
    width: 25px;
`;
const ArrowImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
