import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { mccConfig } from "../../../axiosConfig";

function ContactUs() {
    // Input States
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [errormsg, setErrormsg] = useState("");
    const [errormsgtext, setErrormsgtext] = useState("");

    const handlesubmit = async (e) => {
        // e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", mail);
        formData.append("message", message);

        mccConfig
            .post("api/v1/general/contact-details/", formData)
            .then((response) => {
                console.log(response);
                setErrormsg("");
                setErrormsgtext("");
                setName("");
                setMail("");
                setMessage("");
                const successMessageElement = document.getElementById("successMessage");
                successMessageElement.textContent = "Message sent successfully!";
                successMessageElement.style.display = "block";

                setTimeout(() => {
                    successMessageElement.style.display = "none";
                }, 3000);

                // Handle successful response here
            })
            .catch((err) => {
                if (err.response) {
                    setErrormsg(err.response.data.email);
                    // Access and log error message

                    setErrormsgtext(err.response.data.name);
                } else {
                    console.log("Network error:", err.message);
                }
            });
    };

    function handleInputChange(event) {
        const { value } = event.target;
        // Use a regular expression to test for non-numeric input
        if (/^[A-Za-z]+$/.test(value) || value === "") {
            setName(value);
        }
    }

    return (
        <>
            <MainContainer id="contact" name="contact">
                <WrapperContainer className="wrapper">
                    <TopContainer>
                        <Small>- CONTACT US</Small>
                        <Heading>
                            <span>Get</span> a Quote
                        </Heading>
                    </TopContainer>
                    <SubContainer>
                        <LeftContainer>
                            <FieldBox>
                                <InputRow>
                                    <Input
                                        value={name}
                                        type="text"
                                        placeholder="Full Name"
                                        onChange={handleInputChange}
                                    />
                                    <Errormessage>{errormsgtext ? errormsgtext : ""}</Errormessage>
                                </InputRow>
                                <InputRow>
                                    <Input
                                        value={mail}
                                        type="mail"
                                        placeholder="Email"
                                        onChange={(e) => setMail(e.target.value)}
                                    />
                                    <Errormessage>{errormsg ? errormsg : ""}</Errormessage>
                                </InputRow>

                                <InputRow>
                                    <textarea
                                        value={message}
                                        cols="30"
                                        rows="10"
                                        placeholder="Type your message..."
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                </InputRow>
                            </FieldBox>
                            <SubmitButton
                                onClick={(e) => {
                                    handlesubmit(e);
                                }}
                            >
                                Submit
                            </SubmitButton>
                            <p id="successMessage" class="hidden">
                                Email sent successfully!
                            </p>
                        </LeftContainer>
                        <RightContainer>
                            <SubHeading>Are You Going to Implement Project ?</SubHeading>
                            <MainList>
                                <SubList>
                                    <IconContainer className="location">
                                        <IconImg
                                            src={
                                                require("../../assets/images/location-logo.svg")
                                                    .default
                                            }
                                        />
                                        <SubTitle>ADDRESS</SubTitle>
                                    </IconContainer>
                                    <ContentBox>
                                        <Para>
                                            2nd Floor, Logtech Tower, Bypass Junction,
                                            Kazhakkoottam, Trivandrum, Kerala, India - 695582
                                        </Para>
                                    </ContentBox>
                                </SubList>
                                <SubList>
                                    <IconContainer>
                                        <IconImg
                                            src={
                                                require("../../assets/images/mail-logo.svg").default
                                            }
                                        />
                                        <SubTitle>EMAIL</SubTitle>
                                    </IconContainer>
                                    <ContentBox>
                                        <Para>business@malabarconstructions.com</Para>
                                    </ContentBox>
                                </SubList>
                                <SubList>
                                    <IconContainer>
                                        <IconImg
                                            src={
                                                require("../../assets/images/call-logo.svg").default
                                            }
                                        />
                                        <SubTitle>PHONE</SubTitle>
                                    </IconContainer>
                                    <ContentBox>
                                        <Para>+91 9846949989</Para>
                                    </ContentBox>
                                </SubList>
                            </MainList>
                        </RightContainer>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default ContactUs;

const MainContainer = styled.div`
    padding: 100px 0px;
    background-color: #e9f9ff;
    @media all and (max-width: 980px) {
        padding: 70px 0px;
    }
`;
const WrapperContainer = styled.div``;
const TopContainer = styled.div``;
const Small = styled.h4`
    margin-bottom: 20px;
`;
const Heading = styled.h2`
    font-family: "poppins_medium";
    font-size: 37px;
    margin-bottom: 20px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 480px) {
        font-size: 34px;
    }
    @media all and (max-width: 360px) {
        font-size: 30px;
    }
`;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 47%;
    .hidden {
        display: none;
        color: green;
    }

    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 30px;
    }
`;
const SubmitButton = styled.button`
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;
    background: #00aae8;
    height: 48px;
    font-family: "poppins_medium";
    transition: all 0.3s ease 0s;
`;
const FieldBox = styled.div``;
const InputRow = styled.div`
    margin-bottom: 30px;
    p {
        color: red;
    }
    textarea {
        background: #ffffff;
        border: 1px solid #e3e3e3;
        font-size: 16px;
        resize: none;
        width: 100%;
        height: 120px;
        padding: 8px;
        margin-bottom: 0;
    }
`;
const Input = styled.input`
    background: #ffffff;
    border: 1px solid #e3e3e3;
    padding: 14px;
    font-size: 16px;
    color: #a0a0a0;
    width: 100%;
`;
const RightContainer = styled.div`
    width: 47%;
    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 30px;
    }
`;
const SubHeading = styled.h3`
    color: #215366;
    font-size: 28px;
    width: 63%;
    margin-bottom: 30px;
    @media all and (max-width: 1200px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        font-size: 25px;
    }
`;
const MainList = styled.ul``;
const SubList = styled.li`
    width: 70%;
    margin-bottom: 30px;
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
const IconContainer = styled.div`
    width: 20px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    &.location {
        width: 40px;
        @media all and (max-width: 1080px) {
            width: 80px;
        }
    }
`;
const IconImg = styled.img`
    margin-right: 10px;
`;
const ContentBox = styled.div``;
const SubTitle = styled.h5`
    color: #215366;
    font-family: "poppins_medium";
    font-size: 14px;
`;
const Para = styled.p`
    color: #222;
    font-size: 16px;
`;
const Errormessage = styled.span`
    color: red;
    display: block;
    font-size: 14px;
`;
