import React from "react";
import { styled } from "styled-components";

import bgimage from "../../assets/images/service-top-bg.png";

function Services() {
    return (
        <>
            <MainContainer id="service" name="service">
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <ServiceCards className="main">
                            <Small>- SERVICES</Small>
                            <Title>
                                Building <span>Solutions</span> That Last
                            </Title>
                            <MainList>
                                <SubList>
                                    <ImgBox>
                                        <ArrowImg
                                            src={
                                                require("../../assets/images/tick-icon.svg").default
                                            }
                                        />
                                    </ImgBox>
                                    <Heading>Civil and Structural Construction</Heading>
                                </SubList>
                                <SubList>
                                    <ImgBox>
                                        <ArrowImg
                                            src={
                                                require("../../assets/images/tick-icon.svg").default
                                            }
                                        />
                                    </ImgBox>
                                    <Heading>Full Turnkey Contract Works</Heading>
                                </SubList>
                                <SubList>
                                    <ImgBox>
                                        <ArrowImg
                                            src={
                                                require("../../assets/images/tick-icon.svg").default
                                            }
                                        />
                                    </ImgBox>
                                    <Heading>Design to Project Execution support</Heading>
                                </SubList>
                            </MainList>
                        </ServiceCards>
                        <ServiceCards>
                            <IconBox>
                                <IconImg
                                    src={require("../../assets/images/service-main.svg").default}
                                />
                            </IconBox>
                            <SubHeading>Civil and Structural Construction</SubHeading>
                            <Para>
                                Meticulous construction and execution, guided by precise designs and
                                site plans. Earthworks, structural, and geotechnical engineering
                                expertise. Skilled labor, supervised by experts, delivers prideful
                                civil construction projects.
                            </Para>
                        </ServiceCards>
                        <ServiceCards>
                            <IconBox>
                                <IconImg
                                    src={require("../../assets/images/service-two.svg").default}
                                />
                            </IconBox>
                            <SubHeading>Full Turnkey Contract Works</SubHeading>
                            <Para>
                                Beyond structures, we offer complete facility erection services
                                under full turnkey contracts. From architecture to plumbing and
                                electrical works, experience our high-quality project management and
                                satisfaction-guaranteed results.
                            </Para>
                        </ServiceCards>
                        <ServiceCards>
                            <IconBox>
                                <IconImg
                                    src={require("../../assets/images/service-three.svg").default}
                                />
                            </IconBox>
                            <SubHeading>Design to Project Execution Support</SubHeading>
                            <Para>
                                Specialized project management for end-to-end oversight: planning,
                                design, and construction. We handle details, collaborate with
                                consultants, manage value engineering, and expedite permits for
                                on-budget, timely results.
                            </Para>
                        </ServiceCards>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Services;

const MainContainer = styled.div`
    padding: 120px 0px;
    background: #0e232b;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    background-position-y: bottom;
    @media all and (max-width: 768px) {
        padding: 100px 0px;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;
const ServiceCards = styled.div`
    width: 49%;
    padding: 35px;
    border: 1px solid #c2efff;
    margin-bottom: 30px;
    transition: all 0.5s ease 0s;
    &.main {
        border: none;
        padding: 0px;
    }
    &:hover {
        border: 1px solid #00aae8;
        &.main {
            border: none;
        }
    }
    @media all and (max-width: 1200px) {
        padding: 20px;
        width: 48%;
        margin-bottom: 35px;
    }
    @media all and (max-width: 1080px) {
        width: 48%;
        margin-bottom: 30px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;
const Small = styled.h4`
    color: #ffffff;
    margin-bottom: 20px;
`;
const Title = styled.h2`
    color: #ffffff;
    font-family: "poppins_medium";
    font-size: 37px;
    width: 80%;
    margin-bottom: 20px;
    span {
        color: #00acea;
    }
    @media all and (max-width: 1200px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 32px;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
    }
`;
const MainList = styled.ul``;
const SubList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;
const ImgBox = styled.div`
    width: 30px;
    margin-right: 10px;
    @media all and (max-width: 768px) {
        width: 20px;
    }
`;
const ArrowImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const Heading = styled.p`
    color: #ffffff;
    font-size: 18px;
    @media all and (max-width: 1200px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 15px;
    }
`;
const IconBox = styled.div`
    width: 10%;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        width: 18%;
    }
`;
const IconImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const SubHeading = styled.h2`
    color: #ffffff;
    margin-bottom: 15px;
`;
const Para = styled.p`
    color: #ffffff;
    @media all and (max-width: 1200px) {
        font-size: 15px;
    }
`;
