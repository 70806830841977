import React, { useEffect, useState } from "react";

// Packages
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import HamburgerMenu from "./HamburgerMenu";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

function Header() {
    const [state, setState] = useState("");

    // Hamburger Menu Modal
    const [isMenuModal, setIsMenuModal] = useState(false);
    const location = useLocation();

    // Scroll to top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // This provides a smooth scrolling animation
        });
    }

    const navigate = useNavigate();

    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <SubContainer>
                        <RightContainer>
                            <h1>
                                <a>
                                    <LogoBox to="/">
                                        <Logo
                                            src={require("../assets/images/main-logo.svg").default}
                                            alt="logo"
                                        />
                                    </LogoBox>
                                </a>
                            </h1>
                        </RightContainer>
                        <LeftContainer>
                            <MainList>
                                <SubListhome
                                    onClick={() => {
                                        setState("home");
                                        scrollToTop();
                                    }}
                                    to="/"
                                    className={state === "home" ? "color" : ""}
                                >
                                    Home
                                </SubListhome>
                                <SubListNew
                                    onClick={() => {
                                        setState("about");
                                        navigate("/");
                                    }}
                                    className={state === "about" ? "color" : ""}
                                    to="about"
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    About
                                </SubListNew>
                                <SubListNew
                                    onClick={() => {
                                        navigate("/");
                                        setState("service");
                                    }}
                                    to="service"
                                    className={state === "service" ? "color" : ""}
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    Service
                                </SubListNew>
                                <SubList
                                    onClick={() => {
                                        setState("");
                                    }}
                                    to="/projects"
                                >
                                    Projects
                                </SubList>
                                <SubList
                                    onClick={() => {
                                        setState("");
                                    }}
                                    to="/careers"
                                >
                                    Careers
                                </SubList>
                                <SubListNew
                                    onClick={() => {
                                        navigate("/");
                                        setState("contact");
                                    }}
                                    className={state === "contact" ? "color" : ""}
                                    to="contact"
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    offset={-60}
                                >
                                    Contact
                                </SubListNew>
                            </MainList>
                        </LeftContainer>
                        <HamburgerBox onClick={() => setIsMenuModal(!isMenuModal)}>
                            <HamburgerImg
                                src={require("../assets/images/hamm.svg").default}
                                alt="hamburger"
                            />
                        </HamburgerBox>
                    </SubContainer>
                </WrapperContainer>
            </MainContainer>
            <HamburgerMenu isMenuModal={isMenuModal} setIsMenuModal={setIsMenuModal} />
        </>
    );
}

export default Header;

const MainContainer = styled.div`
    padding: 10px 0px;
    background: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 2;
    &.none {
        display: none;
    }
`;
const WrapperContainer = styled.div``;
const SubContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const RightContainer = styled.div`
    width: 7%;
    h1 {
        width: 100%;
        line-height: 65%;
        a {
            display: inline-block;
            width: 100%;
        }
    }
    @media all and (max-width: 480px) {
        width: 12%;
    }
`;
const LogoBox = styled(NavLink)``;
const Logo = styled.img`
    width: 100%;
    display: block;
    height: 100%;
`;
const LeftContainer = styled.div`
    width: 60%;
    @media all and (max-width: 980px) {
        width: 70%;
    }
`;
const HamburgerBox = styled.div`
    width: 50px;
    display: none;
    @media all and (max-width: 980px) {
        display: block;
    }
`;
const HamburgerImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const MainList = styled.nav`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const SubList = styled(NavLink)`
    display: inline-block;
    &:hover {
        opacity: 0.8;
    }
    &.active {
        font-family: "poppins_medium";
        color: #00acea;
    
    }
`;
const SubListNew = styled(Link)`
    &.color {
        font-family: "poppins_medium";
        color: #00acea;
    }
`;
const SubListhome = styled(NavLink)`
    display: inline-block;
    &:hover {
        opacity: 0.8;
    }
    &.color {
        font-family: "poppins_medium";
        color: #00acea;
    }
`;
