import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

function HamburgerMenu({ isMenuModal, setIsMenuModal }) {
    return (
        <MainContainer className={isMenuModal ? "active" : ""}>
            <InnerContainer>
                <Overlay onClick={() => setIsMenuModal(false)}></Overlay>
                <MenuBox>
                    <TopBox>
                        <CloseBox onClick={() => setIsMenuModal(false)}>
                            <CloseImg
                                src={require("../assets/images/close.svg").default}
                                alt="Close"
                            />
                        </CloseBox>
                    </TopBox>
                    <BottomBox>
                        <MainList onClick={() => setIsMenuModal(false)}>
                            <SubList to="/">Home</SubList>
                            <SubListNew
                                activeClass="active"
                                to="about"
                                spy={true}
                                duration={500}
                                smooth={true}
                                offset={-60}
                                onClick={() => setIsMenuModal(false)}
                            >
                                About
                            </SubListNew>
                            <SubListNew
                                activeClass="active"
                                to="service"
                                spy={true}
                                duration={500}
                                smooth={true}
                                offset={-60}
                                onClick={() => setIsMenuModal(false)}
                            >
                                Services
                            </SubListNew>
                            <SubList to="/projects">Projects</SubList>
                            <SubList to="/careers">Careers</SubList>
                            <SubListNew
                                activeClass="active"
                                to="contact"
                                spy={true}
                                duration={500}
                                smooth={true}
                                offset={-60}
                                onClick={() => setIsMenuModal(false)}
                            >
                                Contact
                            </SubListNew>
                        </MainList>
                    </BottomBox>
                </MenuBox>
            </InnerContainer>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    transform: translateX(100%);
    transition: all 0.1s ease-in;
    opacity: 0;
    @media all and (max-width: 980px) {
        &.active {
            transform: translateX(0);
            opacity: 1;
        }
    }
`;
const InnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;
const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
`;
const MenuBox = styled.div`
    width: 40%;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 55%;
    }
    @media all and (max-width: 400px) {
        width: 60%;
    }
    @media all and (max-width: 360px) {
        width: 65%;
    }
`;
const TopBox = styled.div`
    padding: 15px 15px 0 15px;
    display: flex;
    justify-content: flex-end;
`;
const CloseBox = styled.div`
    width: 20px;
`;
const CloseImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const BottomBox = styled.div`
    @media all and (max-width: 768px) {
        margin-top: 50px;
    }
`;
const MainList = styled.nav``;
const SubList = styled(NavLink)`
    display: block;
    border-bottom: 1px solid #eee;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
`;
const SubListNew = styled(Link)`
    display: block;
    border-bottom: 1px solid #eee;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
`;
export default HamburgerMenu;
